import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { imageUrlFor } from '../../lib/image-url';
import { buildImageObj } from '../../lib/helpers';

const Seo = ({description, lang, meta, keywords, title, image}) => {
  const metaDescription = description || '';
  const siteTitle = title || '';
  const metaImage =
    image && image.asset
      ? imageUrlFor(buildImageObj(image))
          .width(1200)
          .url()
      : '';
  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={siteTitle}
      meta={[
        {
          name: 'title',
          content: siteTitle
        },
        {
          name: 'description',
          content: metaDescription
        },
        {
          property: 'og:title',
          content: siteTitle
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:image',
          content: metaImage
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:title',
          content: siteTitle
        },
        {
          name: 'twitter:description',
          content: metaDescription
        }
      ]
      .concat(
        keywords && keywords.length > 0
          ? {
              name: 'keywords',
              content: keywords.join(', ')
            }
          : []
      )
        .concat(meta)}
    />
  );
}


Seo.defaultProps = {
  lang: 'en',
  meta: [],
};

Seo.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.array,
  image: PropTypes.object,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Seo;