import { graphql, navigate } from 'gatsby';
import { CarouselProvider, Dot, Slide, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import ContactSidebar from '../components/contact/contact-sidebar';
import Container from '../components/global/container';
import CTA from '../components/global/cta';
import Header from '../components/global/header';
import SEO from '../components/global/seo';
import GraphQLErrorList from '../components/graphql-error-list';
import Layout from '../containers/layout';
import { buildImageObj, mapEdgesToNodes } from '../lib/helpers';
import { imageUrlFor } from '../lib/image-url';
import { useForm } from 'react-hook-form'


export const query = graphql`
  query ContactCareersPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      phone
      email
      address
      map
    }
    static: sanityPage(slug: { current: { eq: "contact-careers-page" } }) {
      _rawHeading
      _rawIntro
      seo {
        title
        keywords
        description
        image {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
        }
      }
      media {
        image {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
        }
        video {
          asset {
            _id
            url
          }
        }
        alt
      }
    }
    careers: allSanityCareers(sort: { fields: order }) {
      edges {
        node {
          _id
          order
          title
          _rawDescription
          team {
            team {
              email
            }
          }
        }
      }
    }
  }
`;

const ContactCareersPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const content = (data || {}).static;
  const media = content.media;
  const careers = (data || {}).careers ? mapEdgesToNodes(data.careers) : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const { register, handleSubmit, reset } = useForm()

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&")
  }

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState('false');

  const handlePost = (formData, event) => {
    fetch(`/`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact-form", ...formData }),
    })
      .then((response) => {
        // navigate("#success")
        // alert("Thank you for your submission")
        reset()
        // handleSuccess();
        setIsSuccessfullySubmitted(!isSuccessfullySubmitted);
      })
      .catch((error) => {
        console.log("Error: " + error)
      })
      event.preventDefault()
  }

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.25
  });

  const handleSuccess = (e) => {
    console.log('success');
    setIsSuccessfullySubmitted(isSuccessfullySubmitted)
  }

  return (
    <>
      <Header headerStyle="clear" />
      <Layout>
      {content.seo && (
          <SEO
          title={content.seo.title !== null ? content.seo.title : false}
          description={content.seo.description !== null ? content.seo.description : false}
          keywords={content.seo.keywords !== null ? content.seo.keywords : false}
          image={content.seo.image !== null ? content.seo.image : false}
        />
        )}
        <Container>

          <section className='section contact__form'>
            
            <div className='site-wide'>
              <div className='contact__header'>
                <h1>Have questions? Need guidance? <br/>After that new big idea?</h1>
              </div>
              <div className='grid-container grid-container--4-2'>
                <div className='grid-column'>
                  <div className='contact__form-container'>
                    {isSuccessfullySubmitted && (
                      <form
                      onSubmit={handleSubmit(handlePost)}
                      name="contact-form"
                      method="POST"
                      action="./#success"
                      data-netlify="true"
                      netlify-honeypot="got-ya"
                    >
                      <input type="hidden" name="form-name" value="contact-form" />
                      <input
                        type="hidden"
                        name="formId"
                        value="contact-form"
                        ref={register()}
                      />
                        <div className='form--group'>
                          <label for="name">Your name</label>
                          <input type="text" id="name" name="name"  required ref={register({ required: true })} />
                        </div>

                        <div className='form--group'>
                          <label for="company">Your company</label>
                          <input type="text" id="company" name="company" ref={register()} />
                        </div>

                        <div className='form--group'>
                          <label for="email">Your email address</label>
                          <input type="email" id="email" name="email" required ref={register({
                            required: true,
                            pattern: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/, })} />
                        </div>

                        <div className='form--group'>
                          <label for="enquiry">Your enquiry</label>
                          <textarea type="textarea" id="enquiry" name="enquiry" required ref={register({ required: true })} />
                        </div>

                        <div className='form--group checkbox'>
                          <label for="newsletter">
                            <input type="checkbox" id="newsletter" name="newsletter" value="Yes" ref={register()} />
                            <span class="checkmark"></span>
                            Sign up to receive insights and communications. You can unsubscribe at any time.
                          </label>
                        </div>

                        <label
                          htmlFor="got-ya"
                          style={{
                            position: 'absolute',
                            overflow: 'hidden',
                            clip: 'rect(0 0 0 0)',
                            height: '1px',
                            width: '1px',
                            margin: '-1px',
                            padding: '0',
                            border: '0',
                          }}
                        >
                          Don’t fill this out if you're human:
                          <input tabIndex="-1" name="got-ya" ref={register()} />
                        </label>

                        <div className='form--group'>
                          <button type="submit" id="submit" className="btn">Get in touch</button>
                        </div>
                    </form>
                    )}
                    {isSuccessfullySubmitted === false && (
                      <div className="success">
                        <h3>Thanks for your enquiry, somebody will be in touch soon.</h3>
                      </div>
                    )}
                  </div>
                </div>
                <div className='grid-column'>
                  <ContactSidebar
                      address={site.address}
                      email={site.email}
                      phone={site.phone}
                      map={site.map}
                  />
                </div>
              </div>
            </div>

          </section>
        </Container>
      </Layout>
    </>
  );
};

export default ContactCareersPage;
